'use client';

import { ButtonVariant } from '@shared/ui-components';

import { Button } from '../../components';
import { HOMEPAGE_PATH, loginHandler } from '../../utils';

type ErrorPageContentActionsProps = {
  actionsCmsContent: {
    goBackToHomepage: string;
    signIn: string;
  };
  locale: string;
  returnToBaseUrl: boolean;
  shouldShowSignInButton: boolean;
};

const handleLogin = async (locale: string, returnToBaseUrl: boolean) =>
  returnToBaseUrl
    ? await loginHandler({
        authorizationParameters: { locale },
        options: { redirectTo: HOMEPAGE_PATH },
      })
    : await loginHandler({ authorizationParameters: { locale } });

export const ErrorPageContentActions = ({
  actionsCmsContent,
  locale,
  returnToBaseUrl,
  shouldShowSignInButton,
}: ErrorPageContentActionsProps) => {
  return (
    <>
      <Button to={HOMEPAGE_PATH} variant={ButtonVariant.Secondary}>
        {actionsCmsContent.goBackToHomepage}
      </Button>
      {shouldShowSignInButton && (
        <Button onClick={() => handleLogin(locale, returnToBaseUrl)}>
          {actionsCmsContent.signIn}
        </Button>
      )}
    </>
  );
};
